import axios from "axios";

// get saas info
const checkSaasActive = async () => {
  const url = BASE_URL + "/check-saas-active-and-demo";
  await axios
    .get(url)
    .then((res) => {
      // saas_apiUrl = window.location.protocol + "//" + res.data[0];
      saas_apiUrl = "https://" + res.data[0];
      SAAS_APPLICATION = res.data[1];
      demo = res.data[2];
    })
    .catch((res) => {
      console.log(res);
    });
};

// config
export let production = "YES"; //YES ==live NO ==localhost only change this value
export let demo = "YES"; //YES/NO
export let SAAS_APPLICATION = "NO"; //YES|NO

// default config for saas application
export const saas_key =
  "$2a$12$pkLmD5jZdxd6bSNcTg9YT.g2mXz5gS8JTArdIg68c8RK/d7w2y2Gu";
export let BASE_URL = "";
export let WEBSITE_URL =
  window.location.protocol + "//" + window.location.hostname;
export let saas_apiParams = "";
export let saas_form_data = "";
export let saas_apiUrl = "";
export let SAAS_DEV_SUB_DOMAIN = "test.automatefood.com"; //change it for local dev only

// google recapture
export let RECAPTURE_SITEKEY = "";

if (production === "YES") {
  //for production
  BASE_URL =
    window.location.protocol + "//" + window.location.hostname + "/api/public";
  const DOMAIN = window.location.hostname;

  // let SUBDOMAIN = window.location.protocol + "//" + window.location.hostname;
  let SUBDOMAIN = "https://" + window.location.hostname;

  // for scaffolding
  // BASE_URL =
  //   window.location.protocol +
  //   "//" +
  //   window.location.hostname +
  //   `${window.location.port !== ""
  //     ? `:${window.location.port}`
  //     : `${!window.location.href.includes(".test") ? "/public" : ""}`
  //   }`;

  // saas config
  saas_apiParams = `saas_key=${saas_key}&domain=${SUBDOMAIN}`;
  saas_form_data = {
    headers: { "Access-Control-Allow-Origin": "*" },
    saas_key: saas_key,
    domain: DOMAIN,
  };
} else {
  // for localhost development
  BASE_URL =
    window.location.protocol +
    "//" +
    window.location.hostname +
    "/khadyo/api/public";

  // saas config
  saas_apiParams = `saas_key=${saas_key}&domain=${SAAS_DEV_SUB_DOMAIN}`;
  saas_form_data = {
    headers: { "Access-Control-Allow-Origin": "*" },
    saas_key: saas_key,
    domain: SAAS_DEV_SUB_DOMAIN,
  };
}

checkSaasActive();
